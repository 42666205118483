import { Button, Card, Container, DialogTitle, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
// components
import { DialogAnimate } from '../../components/animate';
import Iconify from '../../components/Iconify';
import Page from '../../components/Page';
// hooks
import useSettings from '../../hooks/useSettings';
import useSubject from '../../hooks/useSubject';
import { UserForm, UserTable } from '../../sections/users';
import { useGetSubjectUsersQuery } from '../../redux/api/userApi';

// ----------------------------------------------------------------------

export default function SubjectUsers() {
  const { subject } = useSubject();

  const { data, isLoading, isFetching } = useGetSubjectUsersQuery(subject?._id, { skip: !subject });

  const { themeStretch } = useSettings();

  const [selected, setSelected] = useState(null);
  const [open, setOpen] = useState(false);

  const handleFormUpdate = (item) => {
    setSelected(item);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setSelected(null);
    setOpen(false);
  };

  return (
    <Page isLoading={isLoading || isFetching} title="Usuarios">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <Grid container>
          <Grid item xs={7} md={8}>
            <Typography variant="h4" sx={{ mb: 5 }}>
              Usuarios
            </Typography>
          </Grid>

          <Grid item xs={5} md={4}>
            <Button
              style={{ float: 'right' }}
              variant="contained"
              startIcon={<Iconify icon={'eva:plus-fill'} width={20} height={20} />}
              onClick={() => setOpen(true)}
            >
              Añadir
            </Button>
          </Grid>
        </Grid>
        <DialogAnimate maxWidht={'md'} open={open} onClose={handleCloseModal}>
          <DialogTitle>{selected ? 'Editar' : 'Añadir'}</DialogTitle>

          <UserForm item={selected || {}} onCancel={handleCloseModal} />
        </DialogAnimate>
        <Card>
          <UserTable title={'Usuarios'} items={data} onRowClick={handleFormUpdate} />
        </Card>
      </Container>
    </Page>
  );
}
